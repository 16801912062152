import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './BookAnAppointment.scss';
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";
import Google from '../../Images/Google.png'

const BookAnAppointment = ({ setBookAnAppointment }) => {
    const [Page1, setPage1] = useState(true);
    const [Page2, setPage2] = useState(false);
    const [Page3, setPage3] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfoUpdate, setCustInfoUpdate] = useState(false);

    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Garage Door Free Estimate',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'CA',
        Zipcode: '',
        Note: ''
    });

    const FinalMessage = `
      # Torrance, CA Garage Door
      Name: ${CustInfo.FirstName + ' ' + CustInfo.LastName}
      Phone: ${CustInfo.PhoneNumber}
      Address: ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}
      Type: ${CustInfo.Service}
      Time: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time}
      Note: ${CustInfo.Note}`

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_n0o8yjw', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setPage1(false)
                setPage2(false)
                setPage3(true)
                setFormSubmiting(false)
                navigate('/thanks')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="BookAnAppointmentMainComponent" style={location == '/' || location == '/sitemap' ? { "top": "60px" } : { "top": "100px" }}>
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Book An Garage Door Service Online | Torrance Garage Door</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Book An Garage Door Service Online | Torrance Garage Door" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Book An Garage Door Service Online | Torrance Garage Door" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.torrancegaragedoor.org/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Easily book your garage door service appointment online in Torrance, CA. Our expert technicians offer installation, repair, and maintenance services to keep your garage door in top shape. Schedule your service today with our convenient online booking system." data-react-helmet="true" />                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Easily book your garage door service appointment online in Torrance, CA. Our expert technicians offer installation, repair, and maintenance services to keep your garage door in top shape. Schedule your service today with our convenient online booking system." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Book An Garage Door Service Online | Torrance Garage Door" data-react-helmet="true" />
                <meta name="keywords" content="Garage Door Broken Spring, Garage Door Spring Repair, Garage Door Broken Rollers, Garage Door Broken Cables, Garage Door Broken Hinges, Garage Door Broken Drums, Garage Door Off Track, Damage Garage Door Section, New Garage Door Installation, Garage Door Opener Won't Open Or Close, Garage Door Opener Remote Is Not Working, Garage Door Opener Keypad Is Not Working, Garage Door Maintenance, Garage Door Installation, Garage Door Opener Stripped Gear, Garage Door Opener Installation, Garage Door Opener Repair, Garage Door Repair, Garage Door Services, Garage Door Near me, Garage Door Spring Replacement, Garage Door Replacement, Garage Door Opener Chain Repair, Garage Door Opener Belt Repair, Garage Door Residential, Commercial Garage Door, Residential Garage Doors" data-react-helmet="true" />
            </Helmet>
            <div className="BookAnAppointmentMainComponentTop">
                <h1>SCHEDULE SERVICE ONLINE</h1>
                <h2>No extra charge for appointments on nights, weekends or holidays</h2>
            </div>
            <div>
                <div className="BookAnAppointmentMainComponentMaps">
                    <h4 style={Page1 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>1. Information </h4>
                    <h4 style={Page2 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>2. Address </h4>
                    <h4 style={Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>3. Note </h4>
                </div>
                {
                    Page1 ?
                        <div className="BookAnAppointmentMainComponentPage1">
                            <div className="BookAnAppointmentMainRightFullName">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>First Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.FirstName}
                                        className='BookAnAppointmentMainRightBoxFirstName'
                                        onChange={(e) => {
                                            CustInfo.FirstName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Last Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.LastName}
                                        className='BookAnAppointmentMainRightBoxLastName'
                                        onChange={(e) => {
                                            CustInfo.LastName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightPhoneAndMaill">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="tel" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Email Address (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Email}
                                        onChange={(e) => {
                                            CustInfo.Email = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightServices">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Service Needed <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Service}
                                        onChange={(e) => {
                                            CustInfo.Service = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} name="" id="">
                                        <option value="Garage Door Repair">Garage Door Repair</option>
                                        <option value="Garage Door Maintenance">Garage Door Maintenance</option>
                                    </select>
                                    <h3>Technician will provide estimate for free on-site. Payment will be required only if/when service is done.</h3>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightDateAndTime">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Date <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.Date}
                                        className='BookAnAppointmentMainRightBoxDate'
                                        onChange={(e) => {
                                            CustInfo.Date = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="date" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Time <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Time}
                                        className='BookAnAppointmentMainRightBoxTime'
                                        onChange={(e) => {
                                            CustInfo.Time = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} >
                                        <option value="8:00AM-11:00AM">8:00AM-11:00AM</option>
                                        <option value="11:00AM-01:00PM">11:00AM-01:00PM</option>
                                        <option value="01:00PM-03:00PM">01:00PM-04:00PM</option>
                                        <option value="04:00PM-07:00PM">04:00PM-07:00PM</option>
                                        <option value="Ask for a Representative">Ask for a Representative</option>
                                    </select>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    if (!CustInfo.FirstName) { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.LastName) { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.PhoneNumber) { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Date) { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Time) { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "1px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.FirstName &&
                                        CustInfo.LastName &&
                                        CustInfo.PhoneNumber &&
                                        CustInfo.Service &&
                                        CustInfo.Date &&
                                        CustInfo.Time
                                    ) {
                                        setPage1(false)
                                        setPage2(true)
                                    } else {
                                        console.log('no')
                                    }
                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page2 ?
                        <div className="BookAnAppointmentMainComponentPage2">
                            <div className="BookAnAppointmentMainRightAddress1">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 1 <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxAddress1'
                                        defaultValue={CustInfo.Address1}
                                        onChange={(e) => {
                                            CustInfo.Address1 = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress2">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 2 (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Address2}
                                        onChange={(e) => {
                                            CustInfo.Address2 = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress3">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>City <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxCity'
                                        defaultValue={CustInfo.City}
                                        onChange={(e) => {
                                            CustInfo.City = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>State <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.State}
                                        onChange={(e) => {
                                            CustInfo.State = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }}>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Zipcode <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxZipcode'
                                        defaultValue={CustInfo.Zipcode}
                                        onChange={(e) => {
                                            CustInfo.Zipcode = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="tel" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    setPage1(true)
                                    setPage2(false)
                                    setPage3(false)
                                }}>PREVIOUS</button>
                                <button onClick={() => {
                                    console.log(CustInfo)
                                    if (!CustInfo.Address1) { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.City) { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Zipcode) { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "1px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.Address1 &&
                                        CustInfo.City &&
                                        CustInfo.Zipcode
                                    ) {
                                        setPage1(false)
                                        setPage2(false)
                                        setPage3(true)
                                    } else {
                                        console.log('no')
                                    }

                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page3 ?
                        <div className="BookAnAppointmentMainComponentPage3">
                            <div className="BookAnAppointmentMainRightNote">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Note</h4>
                                    <textarea
                                        defaultValue={CustInfo.Note}
                                        onChange={(e) => {
                                            CustInfo.Note = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {
                                    setPage1(false)
                                    setPage2(true)
                                    setPage3(false)
                                }}>PREVIOUS</button>

                                {
                                    !FormSubmiting ?
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <input type="hidden" name="from_name" defaultValue={FinalMessage} />
                                            {/* Cust Info */}
                                            <input type="hidden" name="Date" defaultValue={CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time} />
                                            <input type="hidden" name="Service_Type" defaultValue={CustInfo.Service} />
                                            <input type="hidden" name="Send_To" defaultValue={CustInfo.Email} />
                                            <input type="hidden" name="Cust_Name" defaultValue={CustInfo.FirstName} />
                                            <input type="hidden" name="Company_From" defaultValue={`Torrance, CA Garage Door Repair | SENEER`} />
                                            <input type="hidden" name="Company" defaultValue={`Torrance, CA Garage Door Repair | SENEER | ${CustInfo.City + ', ' + CustInfo.State}`} />
                                            <input type="submit" value="SEND" />
                                        </form>
                                        :
                                        null
                                }

                                {
                                    FormSubmiting ?
                                        <button class="buttonload">
                                            <i class="fa fa-refresh fa-spin"></i>Loading
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        : null
                }
                <div className="BookAnAppointmentMainComponentEmergery">
                    <div className="BookAnAppointmentMainComponentRates">
                        <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={Google} alt="Torrance Garage Door Repair Google Review" />
                        </div>
                        <h3>4.9 Stars - Based on 1,583 Reviews</h3>
                    </div>
                    <div className="BookAnAppointmentMainComponentEmergeryBox">
                        <a href="tel:424-319-1469">For emergency service call: 424-319-1469</a>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default BookAnAppointment;
